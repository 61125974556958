import { createGlobalStyle } from "styled-components";
import homeLightImage1 from "../assets/Images/LightHome1.jpg";
import homeLightImage2 from "../assets/Images/LightHome2.jpg";
import homeDarkImage1 from "../assets/Images/DarkHome1.jpg";
import homeDarkImage2 from "../assets/Images/DarkHome2.jpg";
import errorLightImage from "../assets/Images/LightError.jpg";
import errorDarkImage from "../assets/Images/DarkError.jpg";
import lightPodium from "../assets/Images/LightPodium.png";
import darkPodium from "../assets/Images/DarkPodium.png";
import maintenanceLightImage from "../assets/Images/LightMaintenance.jpg";
import maintenanceDarkImage from "../assets/Images/DarkMaintenance.jpg";

export const lightTheme = {
  body: "#ffffff",
  fontColor: "#101010",
  fontColor2: "#000000" /* Header text color without background */,
  fontColor3: "#e5e5e5" /* Header text color with background applied */,
  fontColor4: "#000000",
  fontColorHover: "#464646" /* Hyperlink hover */,
  borderColor: "#bfbfbf",
  backgroundNavbar: "#1b1b1b",
  background1: "#f1f1f1" /* Background for the races module on the main page */,
  background2: "#f5f5f5" /* Background for the data module on the main page */,
  background3: "#1b1b1b" /* Background calendar */,
  background4: "#383838" /* Background calendar current day */,
  background5: "#1b1b1b" /* Background tooltip */,
  background6: "#2b2b2b" /* Background top home */,
  homeImage1: `url(${homeLightImage1})`,
  homeImage2: `url(${homeLightImage2})`,
  errorImage: `url(${errorLightImage})`,
  maintenanceImage: `url(${maintenanceLightImage})`,
  colorLink: "#179387",
  colorLinkHover: "#36c7b9",
  colorOpacityRGBA: "52, 52, 52",
  faintEdge: "#d9d9d9", //For td tables
  green: "#31ab0a",
  red: "#d11717",
  orange: "#f57c00",
  backgroundGreen: "#58f1a3",
  backgroundRed: "#f44336",
  standingsCategories: "#dfdfdf",
  retColor: "#ce93d8",
  pointsColor: "#81c784",
  outofpointsColor: "#7986cb",
  winnerColor: "#fff176",
  secondColor: "#bdbdbd",
  thirdColor: "#ffb74d",
  dnqColor: "#e57373",
  othersColor: "#f5f5f5",
  standingsBorder: "#c3c3c3",
  emptyColor: "#f5f5f5",
  bonusColor: "#f06292",
  lightAColor: "#3c5250",
  darkAColor: "#179387",
  GreenAColor: "#317670",
  lightAColorHover: "#2c7a73",
  darkAColorHover: "#36c7b9",
  GreenAColorHover: "#3e9f96",
  resizeIcon:
    "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE4LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgdmlld0JveD0iMCAwIDE4Mi45MzEgMTgyLjkzMSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTgyLjkzMSAxODIuOTMxOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+DQo8cGF0aCBkPSJNMTczLjkzLDkyLjc5OGMtNC45NzEsMC05LDQuMDI5LTksOXY1MC40MDRMMzAuNzI4LDE4aDUwLjQwNGM0Ljk3MSwwLDktNC4wMjksOS05cy00LjAyOS05LTktOUg5QzQuMDMsMCwwLDQuMDI5LDAsOQ0KCXY3Mi4xMzJjMCw0Ljk3MSw0LjAyOSw5LDksOXM5LTQuMDI5LDktOVYzMC43MjlsMTM0LjIwMiwxMzQuMjAyaC01MC40MDRjLTQuOTcxLDAtOSw0LjAyOS05LDlzNC4wMjksOSw5LDloNzIuMTMyDQoJYzQuOTcxLDAsOS00LjAyOSw5LTl2LTcyLjEzMkMxODIuOTMsOTYuODI4LDE3OC45MDEsOTIuNzk4LDE3My45Myw5Mi43OTh6Ii8+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8L3N2Zz4NCg==",
  backgroundRGB: "rgba(0,0,0,0.9);",
  podium: `url(${lightPodium})`,
  modal: "#ffffff",
  modalBorder: "#e3e3e3",
  svgDataTable: "rgb(223 223 223)",
};

export const darkTheme = {
  body: "#000000",
  fontColor: "#fffafa",
  fontColor2: "#bfbfbf" /* Header text color without background */,
  fontColor3: "#e5e5e5" /* Header text color with background applied */,
  fontColor4: "#ffffff",
  fontColorHover: "#e5e5e5" /* Hyperlink hover */,
  borderColor: "transparent",
  backgroundNavbar: "#2c2c2c",
  background1: "#1c1c1c" /* Background for the races module on the main page */,
  background2: "#262626" /* Background for the data module on the main page */,
  background3: "#ffffff" /* Background calendar */,
  background4: "#f1f1f1" /* Background calendar current day */,
  background5: "#e7e7e7" /* Background tooltip */,
  background6: "#141414" /* Background top home */,
  homeImage1: `url(${homeDarkImage1})`,
  homeImage2: `url(${homeDarkImage2})`,
  errorImage: `url(${errorDarkImage})`,
  maintenanceImage: `url(${maintenanceDarkImage})`,
  colorLink: "#3bd8c9",
  colorLinkHover: "#60eddf",
  colorOpacityRGBA: "255, 255, 255",
  faintEdge: "#3c3c3c", //For td tables
  green: "#51e54f",
  red: "#e14c4c",
  orange: "#ffa726",
  backgroundGreen: "#37af7d",
  backgroundRed: "#b71919",
  standingsCategories: "#161616",
  retColor: "#6a1b9a",
  pointsColor: "#2e7d32",
  outofpointsColor: "#283593",
  winnerColor: "#fbc02d",
  secondColor: "#616161",
  thirdColor: "#ef6c00",
  dnqColor: "#c62828",
  othersColor: "#242424",
  standingsBorder: "#404040",
  emptyColor: "#2e2e2e",
  bonusColor: "#d81b60",
  lightAColor: "#3bd8c9",
  darkAColor: "#317670",
  GreenAColor: "#a1c9c5",
  lightAColorHover: "#60eddf",
  darkAColorHover: "#569d96",
  GreenAColorHover: "#a2fff6",
  resizeIcon:
    "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTRweCIgaGVpZ2h0PSIxNHB4IiB2aWV3Qm94PSIwIDAgMTQgMTQiIHZlcnNpb249IjEuMSI+CjxnIGlkPSJzdXJmYWNlMSI+CjxwYXRoIHN0eWxlPSIgc3Ryb2tlOm5vbmU7ZmlsbC1ydWxlOm5vbnplcm87ZmlsbDpyZ2IoMTAwJSwxMDAlLDEwMCUpO2ZpbGwtb3BhY2l0eToxOyIgZD0iTSAxMy4zMDQ2ODggNy4wOTc2NTYgQyAxMi45MjU3ODEgNy4wOTc2NTYgMTIuNjE3MTg4IDcuNDA2MjUgMTIuNjE3MTg4IDcuNzg5MDYyIEwgMTIuNjE3MTg4IDExLjY0NDUzMSBMIDIuMzUxNTYyIDEuMzc4OTA2IEwgNi4yMDcwMzEgMS4zNzg5MDYgQyA2LjU4NTkzOCAxLjM3ODkwNiA2Ljg5NDUzMSAxLjA3MDMxMiA2Ljg5NDUzMSAwLjY4NzUgQyA2Ljg5NDUzMSAwLjMwODU5NCA2LjU4NTkzOCAwIDYuMjA3MDMxIDAgTCAwLjY4NzUgMCBDIDAuMzA4NTk0IDAgMCAwLjMwODU5NCAwIDAuNjg3NSBMIDAgNi4yMDcwMzEgQyAwIDYuNTg1OTM4IDAuMzA4NTk0IDYuODk0NTMxIDAuNjg3NSA2Ljg5NDUzMSBDIDEuMDcwMzEyIDYuODk0NTMxIDEuMzc4OTA2IDYuNTg1OTM4IDEuMzc4OTA2IDYuMjA3MDMxIEwgMS4zNzg5MDYgMi4zNTE1NjIgTCAxMS42NDQ1MzEgMTIuNjE3MTg4IEwgNy43ODkwNjIgMTIuNjE3MTg4IEMgNy40MDYyNSAxMi42MTcxODggNy4wOTc2NTYgMTIuOTI1NzgxIDcuMDk3NjU2IDEzLjMwNDY4OCBDIDcuMDk3NjU2IDEzLjY4NzUgNy40MDYyNSAxMy45OTYwOTQgNy43ODkwNjIgMTMuOTk2MDk0IEwgMTMuMzA0Njg4IDEzLjk5NjA5NCBDIDEzLjY4NzUgMTMuOTk2MDk0IDEzLjk5NjA5NCAxMy42ODc1IDEzLjk5NjA5NCAxMy4zMDQ2ODggTCAxMy45OTYwOTQgNy43ODkwNjIgQyAxMy45OTYwOTQgNy40MDYyNSAxMy42ODc1IDcuMDk3NjU2IDEzLjMwNDY4OCA3LjA5NzY1NiBaIE0gMTMuMzA0Njg4IDcuMDk3NjU2ICIvPgo8L2c+Cjwvc3ZnPgo=",
  backgroundRGB: "rgba(255,255,255,0.9);",
  podium: `url(${darkPodium})`,
  modal: "#0e0e0e",
  modalBorder: "#424242",
  svgDataTable: "rgb(223 223 223)",
};

export const GlobalStyles = createGlobalStyle`
.dropdown-container-lt {
  color: ${(props: any) => props.theme.body};
}

.dropdown-container-lt > div {
  background-color: ${(props: any) => props.theme.fontColor};
}

.dropdown-container-lt > div : hover {
  background-color: ${(props: any) => props.theme.colorLink};
}

#drag-zone span:hover {
  background-color: ${(props: any) => props.theme.colorLink};
}
#last-gp-layout-container .mycanvas-container {
  background: ${(props: any) => props.theme.background} !important;

}
#main-inner-text, .fa-circle-arrow-left, .categoryWrapper button > span > span > span {
  color: ${(props: any) => props.theme.fontColor};
}
.data-standings .rdt_TableCell:first-child,
.data-standings .rdt_TableCell:last-child {
  background: ${(props: any) => props.theme.background1};
  color: ${(props: any) => props.theme.fontColor};
}

.data-standings .rdt_TableCell:not(:first-child):not(:last-child) {
  background: ${(props: any) => props.theme.emptyColor};
  color: ${(props: any) => props.theme.fontColor};
}
#main-image {
  background-image: ${(props: any) => props.theme.homeImage1};
}
#secondary-image {
  background-image: ${(props: any) => props.theme.homeImage2};
}
.form-check-input:focus {
  border: 1px solid ${(props: any) => props.theme.borderColor};
}
.navbar-fixed,
.dropdown-menu,
.dropdown-menu a:hover,
.dropdown-menu a:focus {
  background: ${(props: any) => props.theme.backgroundNavbar} !important;
}
.nextGP, .standings-title {
  background: ${(props: any) => props.theme.background6};
}

.nav-link, .navbar-brand, .categoryWrapper h1{
  color: ${(props: any) => props.theme.fontColor2} !important;
}
.categoryWrapper span:before, .categoryWrapper span:after, .categoryWrapper button, .categoryWrapper:hover button > span > span:before {
  border-color: ${(props: any) => props.theme.fontColor2} !important;
}
.categoryWrapper button > span > span > span:after {
  background: ${(props: any) => props.theme.fontColor2} !important;
  color: ${(props: any) => props.theme.body};
}

.categoryWrapper:hover button > span > span > span {
  color: ${(props: any) => props.theme.fontColor2}};
}
.navbar-fixed a {
  color: ${(props: any) => props.theme.fontColor3} !important;
}
#main-races {
  background: ${(props: any) => props.theme.background1};
}
#main-data, #lastgp-standings {
  background: ${(props: any) => props.theme.background2};
  color: ${(props: any) => props.theme.fontColor};
}
.file-container > label:first-child, table thead, table tfoot,
.best-times-standings .rdt_TableCol,
#drivers-licenses .rdt_TableCol,
#slot-absents p, #slot-unconf p
 {
  color: ${(props: any) => props.theme.background2};
  background: ${(props: any) => props.theme.fontColor};
  border: 1px solid ${(props: any) => props.theme.standingsBorder};
}
#slot-absents, #slot-unconf {
  border: 1px solid ${(props: any) => props.theme.standingsBorder};
}
.driver-summary .rdt_TableHead div,
.driver-results .rdt_TableHead div,
.rdt_TableHeadRow,
.rdt_TableHeadRow,
.rdt_Pagination,
.driver-summary-table-container .rdt_TableRow:last-child .rdt_TableCell {
  color: ${(props: any) => props.theme.background2};
  background: ${(props: any) => props.theme.fontColor} !important;
}
table thead th, table tfoot th{
  border: 1px solid ${(props: any) => props.theme.standingsBorder};
}
#secondary-image, .license-penalty-container > span a {
  color: ${(props: any) => props.theme.fontColor};
}
#main-info {
  background: ${(props: any) => props.theme.background1};
  color: ${(props: any) => props.theme.fontColor};
}
#main-info p, .default-color {
  color: ${(props: any) => props.theme.fontColor};
}#main-info span {
  color: ${(props: any) => props.theme.fontColor2};
}
#main-races p {
  color: ${(props: any) => props.theme.fontColor};
}
.react-calendar {
  background: ${(props: any) => props.theme.background3}
}
.update-claim {
  background: ${(props: any) => props.theme.backgroundRGB}
  color: ${(props: any) => props.theme.background1}
}
.react-calendar__month-view__weekdays {
  color: ${(props: any) => props.theme.background1};
}
.react-calendar__tile:not(.day-selected) abbr,
.react-calendar__tile:not(.day-selected-sec) abbr,
.react-calendar__tile:not(.day-selected-eve) abbr {
  color: ${(props: any) => props.theme.background1};
}
.react-calendar__tile--now::before {
  border: 4px solid  ${(props: any) =>
    props.theme.background2}; !important; /* Define el estilo del borde */
}

.news-item {
  border-right: 2px solid ${(props: any) => props.theme.standingsBorder};
  border-bottom: 2px solid ${(props: any) => props.theme.standingsBorder};
}
.carousel-content {
  border-left: 4px solid ${(props: any) => props.theme.standingsBorder};
  border-right: 1px solid ${(props: any) => props.theme.standingsBorder};
}
.carousel-content h4 {
  background: ${(props: any) => props.theme.standingsBorder};
}
.driver
 {
  color: ${(props: any) => props.theme.colorLink};
}
.claim svg, 
#penalties-container svg,
#content-container a:not(.gp-driver-container a)
#last-gp-container a
 {
  color: ${(props: any) => props.theme.colorLink} !important;
}
.active-menu > a, .dropdown-menu a:hover, .dropdown-menu a:focus, a.active-menu {
  text-decoration-color: ${(props: any) => props.theme.colorLink} !important;
}
.driver:hover,
#content-container a:hover {
  color: ${(props: any) => props.theme.colorLinkHover};
}
.white-driver {
  color: ${(props: any) => props.theme.fontColor2};
}
.full-white-driver, .full-white-driver:hover, #last-gp-container > div a  {
  color: ${(props: any) => props.theme.fontColor4};
}
.white-driver:hover {
  color: ${(props: any) => props.theme.fontColor};
}

.milestone-driver span{
  color: ${(props: any) => props.theme.fontColor2};
}

.scroll-button {
  background: rgba(${(props: any) => props.theme.colorOpacityRGBA}, 0.7);
  color: ${(props: any) => props.theme.body};
}

.scroll-button:hover {
  background: rgba(${(props: any) => props.theme.colorOpacityRGBA}, 1);
  color: ${(props: any) => props.theme.body};
}
.app-container, body {
  background: ${(props: any) => props.theme.background1};
  color: ${(props: any) => props.theme.fontColor};
}
.frame-turqoise .frame-title  {
  background: ${(props: any) => props.theme.background1};
}

[data-tooltip]:after {
  background: ${(props: any) => props.theme.background5};
  color: ${(props: any) => props.theme.background1};
  border: black;
}
[data-tooltip][data-flow="top"]::before {
  border-top-color: ${(props: any) => props.theme.background5};
}
[data-tooltip][data-flow="left"]::before {
  border-left-color: ${(props: any) => props.theme.background5};
}
[data-tooltip][data-flow="right"]::before {
  border-right-color: ${(props: any) => props.theme.background5};
}
[data-tooltip][data-flow="bottom"]::before {
  border-bottom-color: ${(props: any) => props.theme.background5};
}
.backgrounded-title,
.table-stats-container .rdt_TableCol,
.table-stats-container .rdt_Pagination {
  background: ${(props: any) => props.theme.fontColor};
  color: ${(props: any) => props.theme.background1} !important;
}
.corner-borders:before,
.corner-borders:after,
.corner-borders > :first-child:before,
.corner-borders > :first-child:after,
.didyouknow2 {
  border-color: ${(props: any) => props.theme.fontColor};
}
@media (max-width: 991px) {
  .navbar {
    background: ${(props: any) => props.theme.backgroundNavbar} !important;
  }
}
#error-container {
  background-image: ${(props: any) => props.theme.errorImage};
}

#maintenance-container {
  background-image: ${(props: any) => props.theme.maintenanceImage};
}

.submenu-button {
  color: ${(props: any) => props.theme.fontColor2};
}

.submenu-button:hover {
  color: ${(props: any) => props.theme.fontColorHover};
}

.bottomBorder {
  border-bottom: 1px solid ${(props: any) => props.theme.fontColor}
}
.text-penalties-container, table thead, table tfoot {
  border: 1px solid ${(props: any) => props.theme.fontColor};
}
table td {
  border: 1px solid ${(props: any) => props.theme.faintEdge};
}

.team-table tbody, .team-delimiter {
  border-color: ${(props: any) => props.theme.faintEdge};
}

.green {
  color: ${(props: any) => props.theme.green} !important;
}
.red {
  color: ${(props: any) => props.theme.red} !important;
}
.orange {
  color: ${(props: any) => props.theme.orange} !important;
}
.button-green {
  background: ${(props: any) => props.theme.green} !important;
}
.button-red {
  background: ${(props: any) => props.theme.red} !important;
}
.button-orange {
  background: ${(props: any) => props.theme.orange} !important;
}
.button-green:hover {
  background: ${(props: any) => props.theme.green} !important;
}
.button-red:hover {
  background: ${(props: any) => props.theme.red} !important;
}
.button-orange:hover {
  background: ${(props: any) => props.theme.orange} !important;
}
.background-green {
  background: ${(props: any) => props.theme.backgroundGreen};
}
.background-red {
  background: ${(props: any) => props.theme.backgroundRed};
}
.checkmark {
  background-color: ${(props: any) => props.theme.fontColor3};
}

.standings-points, .standings-position, .results-position {
  background : ${(props: any) => props.theme.fontColor};
  color: ${(props: any) => props.theme.body};
}
.hpRNJ  {
  color : ${(props: any) => props.theme.fontColor};
  background: ${(props: any) => props.theme.background1};
}
.standings-Empty, {
  background: ${(props: any) => props.theme.emptyColor} !important;
}
.teamdriver-results-container {
  background: ${(props: any) => props.theme.emptyColor};
}
.standings-table thead, 
.standings-table tfoot, 
.standings-pos,
.standings-tab-points,
.data-standings .rdt_TableHead div,
.data-standings .rdt_TableRow > div:first-child,
.data-standings .rdt_TableRow .rdt_TableCell:last-child
 {
  background: ${(props: any) => props.theme.standingsCategories};
  color: ${(props: any) => props.theme.fontColor};
}

.standings-Ret {
  background: ${(props: any) => props.theme.retColor};
  color: ${(props: any) => props.theme.fontColor};
}

.standings-POINTS {
  background: ${(props: any) => props.theme.pointsColor};
  color: ${(props: any) => props.theme.fontColor};
}
.standings-OUT_OF_POINTS {
  background: ${(props: any) => props.theme.outofpointsColor};
  color: ${(props: any) => props.theme.fontColor};
}
.standings-BONUS {
  background: ${(props: any) => props.theme.bonusColor};
  color: ${(props: any) => props.theme.fontColor};
}
.standings-WINNER {
  background: ${(props: any) => props.theme.winnerColor};
}
.standings-SECOND {
  background: ${(props: any) => props.theme.secondColor};
  color: ${(props: any) => props.theme.fontColor};
}
.standings-THIRD {
  background: ${(props: any) => props.theme.thirdColor};
}
.standings-DNQ {
  background: ${(props: any) => props.theme.dnqColor};
}
.standings-Empty,
.standings-DNP,
.standings-DNS,
.standings-EX,
.standings-C,
.standings-INJ,
.driver-results .rdt_TableCell,
.driver-summary .rdt_TableCell,
#drivers-licenses .rdt_TableCell,
.table-stats-container .rdt_TableCell,
.historic-modal .rdt_TableCell,
#summary-nationalities .rdt_TableCell,
.livetiming-table-container .rdt_TableCell {
  background: ${(props: any) => props.theme.othersColor};
  color: ${(props: any) => props.theme.fontColor};
}

.standings-table td,
.standings-table thead,
.standings-table thead th,
.standings-table tfoot,
.standings-table tfoot th {
  border: 1px solid ${(props: any) => props.theme.standingsBorder};
}

.rdt_TableRow .rdt_TableCell:last-child,
.rdt_TableHeadRow .rdt_TableCol:last-child,
.rdt_TableBody .rdt_TableRow:last-child,
.rdt_TableCol,
.rdt_TableCell,
.rdt_TableCol,
.rdt_TableRow,
.rdt_TableHeadRow,
.driver-results .rdt_TableCell > div > div:not(:last-child),
.events-container > div,
#driver-entries-container > div,
#driver-entries-container,
#reserve-drivers-container > div,
#event-entries-drivers {
  border-color: ${(props: any) => props.theme.standingsBorder} !important;
} 

.standings-WINNER a,
.standings-THIRD a {
  color: ${(props: any) => props.theme.darkAColor};
}
.standings-WINNER a:hover,
.standings-THIRD a:hover {
  color: ${(props: any) => props.theme.darkAColorHover};
}

.standings-OUT_OF_POINTS a {
  color: ${(props: any) => props.theme.lightAColor};
}
.standings-OUT_OF_POINTS a:hover {
  color: ${(props: any) => props.theme.lightAColorHover};
}
.standings-POINTS a {
  color: ${(props: any) => props.theme.GreenAColor};
}
.standings-POINTS a:hover {
  color: ${(props: any) => props.theme.GreenAColorHover};
}
.checker {
  fill: ${(props: any) => props.theme.fontColor};
}
.react-resizable-handle {
  background-image: url("${(props: any) => props.theme.resizeIcon}");
}

.update-claim .btn-outline-primary {
  color:${(props: any) => props.theme.background1};
  border-color: ${(props: any) => props.theme.background1};
}
.update-claim .btn-outline-primary:hover {
  background:${(props: any) => props.theme.background1};
  color:${(props: any) => props.theme.fontColor};
}

.claim-penalty > b {
  color: ${(props: any) => props.theme.background2};
  background: ${(props: any) => props.theme.fontColor};
}

.claim-penalty {
  border: 2px solid ${(props: any) => props.theme.fontColor};
}

.podium-container{
  background-image: ${(props: any) => props.theme.podium};
}
.modal-content {
  background: ${(props: any) => props.theme.modal};
  border: 1px solid ${(props: any) => props.theme.modalBorder};
  color: ${(props: any) => props.theme.fontColor};
}
.modal-footer, .modal-header { 
  border-color: ${(props: any) => props.theme.modalBorder};
}
.sc-ezWOiH button {
	fill: ${(props: any) => props.theme.svgDataTable};
}
.dropdown-dates p:hover {
	background: ${(props: any) => props.theme.green};
}
`;
